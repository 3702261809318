import React, { useState, useRef } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import './map.css'

const MapMain = () => {
    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: 6.40120505, lng: 80.51022072148571 }}
        >
            <Marker position={{ lat: 6.40120505, lng: 80.51022072148571 }} />
            <Marker position={{ lat: 6.5310929, lng: 80.3964775 }} />
            <Marker position={{ lat: 6.3340647, lng: 80.8538325 }} />
            <Marker position={{ lat: 6.4349489, lng: 81.1308285 }} />
            <Marker position={{ lat: 6.833828, lng: 80.5720627 }} />
            <Marker position={{ lat: 6.9738863, lng: 80.767127 }} />
            <Marker position={{ lat: 8.576425, lng: 81.2344952 }} />
            <Marker position={{ lat: 7.5765074, lng: 79.7956755 }} />
            <Marker position={{ lat: 7.471698, lng: 79.828465 }} />
            <Marker position={{ lat: 9.259087449999999, lng: 80.76743055 }} />


        </GoogleMap>
    ));
    return (
        <div className='mainMainContainer'>
            <h2 className='mainHeading' style={{fontWeight:600,paddingBottom:"2%",display:"flex",justifyContent:"center",alignItems:"center"}}>Locations where the Ingredients are Collected</h2>
            <MyMapComponent isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100vh` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100vh` }} />} />
        </div>
    );
};

export default MapMain;
