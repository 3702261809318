import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';
import { GoogleLogout } from 'react-google-login';

import {
  setSortBy,
  setFilterBy,
  setPageToLoad,
} from '../../store/actions/header';
import { loadProducts } from '../../store/actions/products';
import { config } from '../../services/config';

import './styles.css';

const Header = ({
  location,
  header,
  loadProducts,
  setSortBy,
  setFilterBy,
  setPageToLoad,
  liked
}) => {
  const { pathname } = location;

  function setBrandFilterClick(val) {
    setFilterBy({ brand: val, color: header.filterBy.color });
    setPageToLoad(0);
    loadProducts(
      {
        page: { index: 0, size: config.pageSize },
        filter: { brand: val, color: header.filterBy.color },
        sort: { ...header.sortBy },
      },
      false,
    );
    window.scrollTo(0, 0);
  }

  function setColorFilterClick(val) {
    setFilterBy({ color: val, brand: header.filterBy.brand });
    setPageToLoad(0);
    loadProducts(
      {
        page: { index: 0, size: config.pageSize },
        filter: { color: val, brand: header.filterBy.brand },
        sort: { ...header.sortBy },
      },
      false,
    );
    window.scrollTo(0, 0);
  }

  function setSortClick(key, direction) {
    setSortBy({ key, direction });
    setPageToLoad(0);
    loadProducts(
      {
        page: { index: 0, size: config.pageSize },
        filter: { ...header.filterBy },
        sort: { key, direction },
      },
      false,
    );
    window.scrollTo(0, 0);
  }

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="primary"
      variant="dark"
      fixed="top"
    >
      <Container>
      <LinkContainer to="/home"><Navbar.Brand>Suwa Osu Natural Products</Navbar.Brand></LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" activeKey={pathname}>
            <LinkContainer to="/home">
              <Nav.Link>
                <i className="fa fa-home"></i> Home
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/products">
              <Nav.Link>
                <i className="fa fa-product-hunt"></i> Products
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contactUs">
              <Nav.Link>
                <i className="fa fa-address-book"></i> Contact Us
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/aboutus">
              <Nav.Link>
              <i class="fa fa-info"></i> About Us
              </Nav.Link>
            </LinkContainer>      
            <LinkContainer to="/locations">
              <Nav.Link>
                <i className="fa fa-map-marker"></i> Map
              </Nav.Link>
            </LinkContainer>     
            <LinkContainer to="/liked">
              <Nav.Link>
                <i className="fa fa-heart"></i> Liked{' '}
                {liked.likedProducts.length > 0 && (
                  <Badge pill variant="light">
                    {liked.likedProducts.length}
                  </Badge>
                )}
              </Nav.Link>
            </LinkContainer>

          </Nav>
          <Nav activeKey={pathname}>
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default connect(
  state => ({
    header: state.headerReducer,
    liked: state.likedReducer,
  }),
  {
    setSortBy,
    setFilterBy,
    setPageToLoad,
    loadProducts,
  },
)(withRouter(Header));
