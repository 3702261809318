import React, { Component } from 'react';
import '../Footer/footer.css';
import SuwsOsulogo from '../../static/products/suwa-osu-logo.png';

const Footer = () => {
  return (
    <div>
      <footer class="main-footer">
        <div class="container">
          <div class="footer-content">
            <div class="row">    
              <div class="logo-widget footer-widget">
                <figure class="logo-box">
                  <a href="/home">
                    <img src={SuwsOsulogo} alt="" />
                  </a>
                </figure>
                <ul class="footer-social" style={{padding:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <li>
                      <a href="https://www.facebook.com/suwaosunaturalproducts" target="_blank">
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/suwa_osu_natural_products/" target="_blank">
                      <i class="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://wa.me/94777129594" target="_blank">
                      <i class="fa fa-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a href='mailto:suwaosuherbal@gmail.com'>
                      <i class="fa fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
              </div>
              
              <div class="col-lg-3 col-md-6 col-sm-12 footer-widget">
                <div class="contact-widget footer-widget">
                  <div class="footer-title">Suwa Osu Natural Products</div>
                  <div class="text">
                    <p>
                      Suwa Osu Natural Product is a company that provides herbal
                      medicines, We offer{' '}
                      <i>
                        King Care, Queen Care, Diabetic Care, Liver and Heart
                        Care
                      </i>{' '}
                      medicine products which help you to get relief from
                      various health diseases. We use traditional way of
                      manufacturing these medicines. We Procure them in a safe
                      environment to give our best to the patients who ever use
                      this.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 footer-widget">
                <div class="contact-widget footer-widget">
                  <div class="footer-title">Products</div>
                  <div class="text">
                    <p>King Care</p>
                    <p>Queen Care</p>
                    <p>Heart & Liver care</p>
                    <p>Diabetic Care</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 footer-widget">
                <div class="contact-widget footer-widget">
                  <div class="footer-title">Contacts</div>
                  <div class="text">
                    <p>Suwa Osu Natural Products</p>
                    <p>
                      No. 26, 2nd cross Lane, Kandawala Mawatha, Ratmalana-
                      10390
                    </p>
                    <a href="tel:+94777129594"><p>(+94) 777 129 594</p></a>
                    <a href='mailto:suwaosuherbal@gmail.com'><p>suwaosuherbal@gmail.com</p></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 column">
              <div class="copyright">
                <a href="#">suwaosunaturalproduts</a> &copy; 2022 All Right
                Reserved
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
