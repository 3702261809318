import React, { useState, useRef } from 'react';

import '../ContactUs/contactus.css'

const contactus = () => {
  return (
  <div className='contactUsMainContainer'>
    <ul className='socialMediaLogoMain'>
      <li className='socialMediaLogo'><a className='socialLinks' href="https://wa.me/94777129594" target="_blank"><i style={{color:"white"}} class="fab fa fa-whatsapp fa-3x"></i></a></li>
      <li className='socialMediaLogo'><a className='socialLinks' href="https://www.facebook.com/suwaosunaturalproducts" target="_blank"><i style={{color:"white"}} class="fab fa fa-facebook fa-3x"></i></a></li>
      <li className='socialMediaLogo'><a className='socialLinks' href="https://www.instagram.com/suwa_osu_natural_products/" target="_blank"><i style={{color:"white"}} class="fab fa fa-instagram fa-3x"></i></a></li>
      <li className='socialMediaLogo'><a className='socialLinks' href='mailto:suwaosuherbal@gmail.com'><i style={{color:"white"}} class="fab fa fa-envelope fa-3x"></i></a></li>
      <li className='socialMediaLogo'><a className='socialLinks' href="https://www.youtube.com/channel/UC8ogg0KM5Jcf2fSQfGuLm_Q"><i style={{color:"white"}} class="fab fa fa-youtube fa-3x"></i></a></li>
      <li className='socialMediaLogo'><a className='socialLinks' href="tel:+94777129594"><i style={{color:"white"}} class="fab fa fa-phone fa-3x"></i></a></li>

    </ul>
    </div>
  );
};

export default contactus;
