import * as Types from '../types';
import { showToast } from './toast';
import axios from 'axios';
import qs from 'qs';

import { insertAdvert } from '../../utils';

let adverts = [];

export const loadProductsInit = isLoadMoreRequest => ({
  type: isLoadMoreRequest
    ? Types.LOAD_MORE_PRODUCTS_INIT
    : Types.LOAD_PRODUCTS_INIT,
});

export const loadProductsError = (isLoadMoreRequest, error) => (
  dispatch,
) => {
  dispatch(showToast({ title: 'Error', text: error }));
  dispatch({
    type: isLoadMoreRequest
      ? Types.LOAD_MORE_PRODUCTS_ERROR
      : Types.LOAD_PRODUCTS_ERROR,
    payload: error,
  });
};

export const loadProductsSuccess = (isLoadMoreRequest, products) => ({
  type: isLoadMoreRequest
    ? Types.LOAD_MORE_PRODUCTS_SUCCESS
    : Types.LOAD_PRODUCTS_SUCCESS,
  payload: products,
});

const errorHandler = (successfn, errorAction, dispatch) => {
  return async (...args) => {
    try {
      await successfn(...args);
    } catch (error) {
      if (error.message) {
        dispatch(errorAction(args[1], error.message));
      }
    }
  };
};

export const loadProducts = (params, isLoadMoreRequest, callback) => async (
  dispatch,
) => {
  dispatch(loadProductsInit(isLoadMoreRequest));

  errorHandler(
    async (params, isLoadMoreRequest, callback) => {
      if (!isLoadMoreRequest) {
        const response = await axios.get('/adverts');
        adverts = response.data;
      }

      const strParams = qs.stringify(params);
      const response = await axios.get(`/products?${strParams}`);
      const products = response.data;
      const productsWithAdverts = insertAdvert(products, adverts, 5);
      dispatch(loadProductsSuccess(isLoadMoreRequest, productsWithAdverts));
      if (callback) callback();
    },
    loadProductsError,
    dispatch,
  )(params, isLoadMoreRequest, callback);
};
