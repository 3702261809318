import React, { useEffect, useState, useRef } from 'react';
import ContainerDimensions from 'react-container-dimensions';

import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Footer from '../../components/Footer/footer-main'

import './styles.css';

const Home = () => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  return (
    <>
      <Row>
        <ContainerDimensions>
          {({ width, height }) => (
            <Carousel
              activeIndex={index}
              direction={direction}
              onSelect={handleSelect}
              interval={null}
            >
              <Carousel.Item>
                <img
                  className="img-obj-fit"
                  src={require(`../../static/products/sinharaja-2.jpg`)}
                  alt="First slide"
                  style={{ width }}
                />
                <div className="overlay"></div>
                <Carousel.Caption>
                  <h3 className='welcome-text-style'>Welcome to Suwa Osu Natural Products</h3>
               </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-obj-fit"
                  src={require(`../../static/products/sinharaja-1.jpg`)}
                  alt="Second slide"
                  style={{ width }}
                />
                <div className="overlay"></div>
                <Carousel.Caption>               
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-obj-fit"
                  src={require(`../../static/products/herbal-products-2.jpg`)}
                  alt="Third slide"
                  style={{ width }}
                />
                <div className="overlay"></div>
                <Carousel.Caption>               
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          )}
        </ContainerDimensions>
      </Row>

      <Row className="my-5">
        <Col xs={7} className="mx-auto">
          <blockquote className="blockquote text-center">
            <p className="mb-0">
              A good laugh and a long sleep are the best cures in the doctor's book.<br></br><br></br>
              - Irish proverb -
            </p>
          </blockquote>
        </Col>
      </Row>
    </>
  );
};

export default Home;
