import faker from 'faker';
import React, { useState } from 'react';
import _ from 'lodash';
import { config } from '../services/config';
import { withRouter,useHistory } from 'react-router';


// const [refreshPageLoadDetails, setRefreshPageLoadDetails] = useState(false);

const brandAndImage = _.flatten(
  _.times(20, i => {
    return ['rapala', 'heddon', 'rebel', 'cottoncordel', 'mepps'].map(
      (brand, j) => {
        return { brand, image: `${brand}${i + 1}.jpg` };
      },
    );
  }),
);

var counterProducts=0;
var counterProductShortDescription=0;
var counterProductLongDescription=0;
var counterProductPrice=0;
var counterProductImages=0;
var counterProductImagesOuter=0;


function getRandomInt(min, max) {  
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getProducts = () => {
  const products = _.times(4, index => {   

    return {
      id: index,
      isAdvert: false,
      name: loopProductNames(),
      price: loopProductPrices(),
      brand: brandAndImage[index].brand,
      image: loopProductImages(),
      imageOuter:loopProductImagesOuter(),
      size: getRandomInt(3, 20),
      weight: getRandomInt(4, 40),
      shortDescription: loopProductShortDescrip(),
      modelNum: faker.random.number(),
      country: "QUALITY SRILANKAN PRODUCT 🇱🇰",
      description:
      loopProductLongDescrip(),
    };
  }).sort((a, b) => 0.5 - Math.random());
  return products;
};

const advertisements = _.times(10, index => ({
  id: index,
  isAdvert: true,
  name: faker.commerce.productName(),
  image: faker.image.business(),
  description:
    faker.lorem
      .paragraph()
      .split('.', 3)
      .join('.') + '.',
  link1: faker.internet.url(),
  link2: faker.internet.url(),
  time: faker.date.recent(),
}));

const loopProductNames=()=>{
  var products=["King Care","Queen Care","Liver & Heart Care","Diabetic Care"];
  counterProducts+=1;
 
  if(counterProducts===1){
    return products[0]
  }
  if(counterProducts===2){
    return products[1];
  }
  if(counterProducts===3){
    return products[2];

  }
  if(counterProducts===4){
    counterProducts=0
    return products[3];

  }

  }


const loopProductLongDescrip=()=>{
  let kingCareDescription=["✤ Total happiness during Sexual Act, Strengthen the male sexual organ","✤ Improves Imaciated looks, Provide Youthfullness & Strength, Stimulated Testesterone hormone intake","✤ Relax & Reduce Stress Induced disorders, Rhematic disorders & Joint pains"]
  let QueenCareDescription=["✤ Ideal for those have delayed childbirth","✤ Irregular menstrual disorders","✤ Soothing to the body & womb, Amenorhoea & Menorhoea","✤ Ideal for PCOD & PCOS","✤ Ovums mature, prevents Fibrosis and Endometriosis","✤ Corets Hormonal Imbalances  & Sexual human urge","✤ Very good for regular burning sensation during urination","✤ Improves Vaginal Secretions","✤ Helps to improve low milk production"]
  let LiverAndHeartCareDescription=["✤ Reduce Unwanted Fat, Liver Damage, Fat deposit in Liver","✤ Good for people who suffers from cirrohosis","✤ Good for people who poisoned with alchohol","✤ Good for people who suffers from liver infections","✤ Reduces Obesity, Improve Liver Functions","✤ Maintain Balance of Liver Enzymes(SGOT, SGPT)","✤ Prevent fat deposit in heart and arteries","✤ Lowers LDL, Improves HDL","✤ Prevent Heart attacks","✤ Enhance blood circulation devoid"]
  let DiabeticCareDescription=["✤ Balance the sugar limit of the blood","✤ For all diabetic patients who have not controlled it with medications","✤ In diabetic mellitus the pancreas will be simulated to produce Insulin","✤ No issues by using with Western Medication"]
  var productsLongDescrip=[kingCareDescription,QueenCareDescription,LiverAndHeartCareDescription,DiabeticCareDescription];
  counterProductLongDescription+=1;
  if(counterProductLongDescription===1){
    return productsLongDescrip[0]
  }
  if(counterProductLongDescription===2){
    return productsLongDescrip[1];
  }
  if(counterProductLongDescription===3){
    return productsLongDescrip[2];

  }
  if(counterProductLongDescription===4){
    counterProductLongDescription=0;
    return productsLongDescrip[3];

  }
}

const loopProductShortDescrip=()=>{
  let kingCareDescription="✤ Total happiness during Sexual Act, Strengthen the male sexual organ, Improves Imaciated looks, Provide Youthfullness & Strength, Stimulated Testesterone hormone intake, Relax & Reduce Stress Induced disorders, Rhematic disorders & Joint pains";
  let QueenCareDescription="✤ Ideal for those have delayed childbirth, Irregular menstrual disorders, Soothing to the body & womb, Amenorhoea & Menorhoea, Ideal for PCOD & PCOS, Ovums mature, prevents Fibrosis and Endometriosis, Corets Hormonal Imbalances  & Sexual human urge, Very good for regular burning sensation during urination, Improves Vaginal Secretions, Helps to improve low milk production";
  let LiverAndHeartCareDescription="✤ Reduce Unwanted Fat, Liver Damage, Fat deposit in Liver, Good for people who suffers from cirrohosis, Good for people who poisoned with alchohol, Good for people who suffers from liver infections, Reduces Obesity, Improve Liver Functions, Maintain Balance of Liver Enzymes(SGOT, SGPT), Prevent fat deposit in heart and arteries, Lowers LDL, Improves HDL, Prevent Heart attacks, Enhance blood circulation devoid";
  let DiabeticCareDescription="✤ Balance the sugar limit of the blood, For all diabetic patients who have not controlled it with medications, In diabetic mellitus the pancreas will be simulated to produce Insulin, No issues by using with Western Medication";

  var products=[kingCareDescription,QueenCareDescription,LiverAndHeartCareDescription,DiabeticCareDescription];
  counterProductShortDescription+=1;
  if(counterProductShortDescription===1){
    return products[0]
  }
  if(counterProductShortDescription===2){
    return products[1];
  }
  if(counterProductShortDescription===3){
    return products[2];

  }
  if(counterProductShortDescription===4){
    counterProductShortDescription=0;
    return products[3];

  }
}

const loopProductImages=()=>{
  var productsImages=["KingCare-Outer.jpg","QueenCare-Outer.jpg","Heart&LiverCare-Outer.jpg","DiabeticCare-Outer.jpg"];
  counterProductImages+=1;
  if(counterProductImages===1){
    return productsImages[0]
  }
  if(counterProductImages===2){
    return productsImages[1];
  }
  if(counterProductImages===3){
    return productsImages[2];

  }
  if(counterProductImages===4){
    counterProductImages=0;
    return productsImages[3];

  }
}

const loopProductImagesOuter=()=>{
  var productsImagesOuter=["KingCare-Outer.jpg","QueenCare-Outer.jpg","Heart&LiverCare-Outer.jpg","DiabeticCare-Outer.jpg"];
  counterProductImagesOuter+=1;
  if(counterProductImagesOuter===1){
    return productsImagesOuter[0]
  }
  if(counterProductImagesOuter===2){
    return productsImagesOuter[1];
  }
  if(counterProductImagesOuter===3){
    return productsImagesOuter[2];

  }
  if(counterProductImagesOuter===4){
    counterProductImagesOuter=0;
    return productsImagesOuter[3];

  }
}


const loopProductPrices=()=>{
  var productPrices=["3000.00","3000.00","3000.00","3000.00"];
  counterProductPrice+=1;
  if(counterProductPrice===1){
    return productPrices[0]
  }
  if(counterProductPrice===2){
    return productPrices[1];
  }
  if(counterProductPrice===3){
    return productPrices[2];

  }
  if(counterProductPrice===4){
    counterProductPrice=0;
    return productPrices[3];

  }
}

export const getProductsData = params => {

  let products = getProducts();

  if (params && 'filter' in params && params.filter.brand !== 'none')
    products = products.filter(
      product => product.brand === params.filter.brand,
    );

  if (params && 'filter' in params && params.filter.color !== 'none')
    products = products.filter(
      product => product.color === params.filter.color,
    );

  if (params && 'sort' in params && params.sort.key !== 'none') {
    function compare(a, b) {
      if (a[params.sort.key] < b[params.sort.key]) return -1;
      if (a[params.sort.key] > b[params.sort.key]) return 1;
      return 0;
    }
    products = products.sort(compare);
    if (params.sort.direction === 'desc') products = products.reverse();
  }

  if (params && 'page' in params) {
    products = products.slice(
      parseInt(params.page.index),
      parseInt(params.page.index) + parseInt(params.page.size),
    );
  }

  return products;
};
export const getProductData = id => {

  let products = getProducts();
  let product = {};
  id = parseInt(id);

  if (!Number.isNaN(id) && products.length > id)
    product = products.find(product => product.id === id);

  return product;
};
export const getAdvertisementsData = () => advertisements;
