import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import ReactImageMagnify from 'react-image-magnify';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MySpinner from '../../components/MySpinner';

import { loadProduct } from '../../store/actions/productDetails';
import { likeProduct, unlikeProduct } from '../../store/actions/liked';
import { showToast, hideToast } from '../../store/actions/toast';


import './styles.css';

const ProductDetails = ({
  productDetails,
  loadProduct,
  match,
  likeProduct,
  unlikeProduct,
  liked,
  showToast,
}) => {
  const { product, isLoading, error } = productDetails;

  useEffect(() => {
    loadProduct(match.params.id);
  }, []);

  function toggleLike() {
    if (!isLiked()) {
      likeProduct(product);
      showToast({ title: 'Notification', text: 'You liked the product.' });
    } else {
      unlikeProduct(product);
      showToast({ title: 'Notification', text: 'You unliked the product.' });
    }
  }

  function isLiked() {
    const isLiked =
      liked.likedProducts.length > 0 &&
      liked.likedProducts.find(p => p.id === product.id);
    return isLiked;
  }
  if (error) return <Redirect to={'/error'} />;
  if (isLoading) return <MySpinner key={0} text={'Loading...'} />;

  return (
    product && (
      <div className="card mb-3">
        <div className="row no-gutters">
          <aside className="col-sm-5 border-right" style={{display:"flex",alignContent:"center",justifyContent:"center"}}>
            <div style={{width:"60%"}}>
              <img
                className="main-img d-md-none"
                src={require(`../../static/products/${product.image}`)}
              />
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: product.shortDescription,
                    isFluidWidth: true,
                    src: require(`../../static/products/${product.image}`),
                  },
                  largeImage: {
                    src: require(`../../static/products/${product.image}`),
                    width: 1200,
                    height: 1200,
                  },
                  enlargedImageContainerStyle: {
                    zIndex: 9,
                    backgroundColor: 'white',
                    objectFit: 'cover',
                  },
                  enlargedImageContainerDimensions: {
                    width: '150%',
                    height: '120%',
                  },
                  className: 'd-none d-md-block ',
                }}
              />
            </div>
          </aside>
          <aside className="col-sm-7">
            <article className="p-5">
              <h3 className="title mb-3" style={{color:"#000",fontWeight:"700"}}><b>{product.name}</b></h3>

              <div className="mb-3">
                <var className="price h3 text-success" style={{fontStyle:"normal"}}>
                  <span className="currency">Rs. <span/></span>
                  <span className="num"><b>{product.price}</b></span>
                </var>
              </div>
              <dl>
                <dt>Description</dt>
                <br></br>
                <dd>
                  <p style={{textAlign:"justify",color:"#000"}}>{(product.description).map(description => <ul style={{listStyle: "none"}}><li key={description}> {description} </li></ul>)}</p>
                </dd>
              </dl>
              <dl className="row">
                <dt className="col-sm-3">Made in</dt>
                <dd className="col-sm-9">{product.country}</dd>
              </dl>
              <button
                onClick={toggleLike}
                className={isLiked() ? 'btn btn-success' : 'btn btn-primary'}
              >
                {isLiked() ? 'Liked' : 'Like it'}
              </button>
              </article>
          </aside>
        </div>
      </div>
    )
  );
};

export default connect(
  state => ({
    productDetails: state.productDetailsReducer,
    liked: state.likedReducer,
  }),
  {
    loadProduct,
    likeProduct,
    unlikeProduct,
    showToast,
    hideToast,
  },
)(withRouter(ProductDetails));
