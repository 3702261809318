import React, { useState, useRef } from 'react';
import '../AboutUs/about-us.css'
import suwaOsuLogo from '../../static/products/suwa-osu-logo-2.png'
import Mission from '../../static/products/Achievement.gif'
import Vision from '../../static/products/Vision.gif'
import DoctorSudath from '../../static/products/Dr.Sudath.jpg'

const aboutUs = () => {
    return (
        <div>
            <div class="container-aboutus">
                <div class="row-aboutus">
                    <div class="column-66-aboutus">
                        <h1 class="xlarge-font"><b>About Us</b></h1>
                        <p><span style={{ fontSize: '36px' }}>I am <span style={{color:"#04AA6D"}}>Dr.Sudath Gunathilake</span></span> <span style={{fontSize:"15px",fontStyle:"italic"}}> D.S.A.M.S. (Hons), M.D., M.Ac.F. D.Ac (International), D.Path (India).
                        </span>I am the owner of Suwa Osu Natural Products. Suwa Osu Natural Product is a company that provides herbal medicines, We offer King Care, Queen Care, Diabetic Care, Liver and Heart Care medicine products which help you to get relief from various health diseases. We use traditional way of manufacturing these medicines. These are made by using only natural herbs so it has no side effects to the body. We offer our complete range at reasonable rates. We Procure them in a safe environment to give our best to the patients who ever use this. For any of our herbal products, you can trust and we commit to never dissapoint you.
                            <div style={{ paddingTop: "1%", textAlign: 'justify' }}>
                                <span style={{ fontWeight: 500, fontFamily: "cursive", paddingTop: "1%", paddingBottom: "2%", textAlign: 'justify' }}>These medicines are being made by extracting ingredients from an old palm leaf book written in 1864.</span>
                                <div style={{paddingTop: "1%"}}>
                                1.	 All medicines are manufactured to export quality.
                                <br></br>
                                2.	 These medicines do not contain heavy metals, chemicals that are harmful to the body which is certified internationally.
                                <br></br>
                                3.	 100 years of excellence (including clinical Reports) with positive results.
                                <br></br>
                                4.	 Raw materials  used here have been found at Sinhraja, Kalawana, Embilipitiya, Thanamalwila, Maskeliya, Hanthana Knuckles Site, Trincomalee, Chilaw and at Mullaitivu Jungle.
                                <br></br>
                                5.	 Approximately 30 types of ingredients are used for one drug.
                                </div>
                            </div>
                        </p>
                        <button class="button-extra">Our Products are 100% natural & organic
                        </button>
                    </div>
                    <div class="column-33">
                        <img className='img-aboutus' src={DoctorSudath} width="300" height="471" />
                    </div>
                </div>
            </div>

            <div class="container-aboutus" style={{ backgroundColor: '#f1f1f1'}}>
                <div class="row-aboutus">
                    <div class="column-33-aboutus">
                        <img className='img-aboutus' src={Mission} alt="App" width="335" height="471" />
                    </div>
                    <div class="column-66-aboutus">
                        <h1 class="xlarge-font"><b>Our Mission</b></h1>
                        <p><span style={{ fontSize: '24px' }}>Through the Invention and <span style={{color:"red"}}>Modernization of Herbal Medicine</span></span> we dedicate ourselves to humanity's desire for longer, better, and happier lives. Our promise to our customers and consumers is to constantly give the pure, standardized, and quality-oriented herbal products.
                        We collaborate with health-care professionals to expand access to the high-quality herbal health-care products for the general public and to ensure these herbal products are export in quality.</p>
                        <button class="button-extra" style={{ backgroundColor: 'red' }}>Our Prodcuts are ISO 22333 certified & Export in Quality</button>
                    </div>
                </div>
            </div>

            <div class="container-aboutus">
                <div class="row-aboutus">
                    <div class="column-66-aboutus">
                        <h1 class="xlarge-font"><b>Our Vision</b></h1>
                        <p><span style={{ fontSize: '24px' }}>We are a Company that provides <span style={{color:"#04AA6D"}}>Organic Health Care Ayurveda-Based Products</span></span> and services that help people to live happier, healthier, and naturally longer lives while spreading traditional Ayurveda's ideals around the world.</p>
                        <button class="button-extra">Our Products are JMP certified by the Department of Ayurveda, Sri Lanka</button>
                    </div>
                    <div class="column-33-aboutus">
                        <img className='img-aboutus' src={Vision} width="335" height="471" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default aboutUs;
